import { Injectable } from '@angular/core';
import {
  Auth,
  getRedirectResult,
  User,
  UserCredential,
  IdTokenResult,
  sendEmailVerification,
} from '@angular/fire/auth';
import * as Sentry from '@sentry/angular-ivy';
import _ from 'lodash';
import moment from 'moment';

import { FEATURES, STRIPE_PRODUCTS } from 'src/app/constants';
import { getAppConfig } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { Tenant, Profile } from 'src/models';

import { TenantsService } from './tenants.service';
import { UsersService } from './users.service';

const HAD_AUTH_ERROR_KEY = 'hadAuthError';
const WAS_MANUAL_LOGOUT = 'wasManualLogout';
const WAS_AUTO_SIGN_IN = 'wasAutoSignIn';

const CUSTOM_TOKEN_REQUEST_TYPE = 'customTokenRequest';
const CUSTOM_TOKEN_RESPONSE_TYPE = 'customTokenResponse';

const COOKIE_PREFIX = '__session';

declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  profile: Profile;
  user: User;
  tenant: Tenant;
  credential: UserCredential;
  idTokenResult: IdTokenResult;
  subdomain: string;
  tenantId: string;
  logo: string;
  primaryColor: string;
  authProviders: string[];
  allowedEmailDomains: string[];
  homePageFilters: any[];
  analyticsDisabled: boolean;
  isCompanyAdmin = false;
  isSuperAdmin = false;
  isReady = false;
  authReadyCallbacks = [];
  accountStatus = 'active';
  phoneNumberRequirement = 'none';
  customProfileFields = [];
  integrations: {
    googleCalendarEnabled: boolean;
    outlookEnabled: boolean;
    slackEnabled: boolean;
    teamsEnabled: boolean;
    workosEnabled: boolean;
    zoomEnabled: boolean;
    marketplaceEnabled: boolean;
  };
  workos?: {
    directoryId?: string;
  };
  defaultCommunityId?: string;
  defaultFieldSettings?: Record<string, { public?: boolean }>;
  permissions?: {
    canCreatePlans?: boolean;
    canCreatePosts?: boolean;
    canCreateOrganizers?: boolean;
    canViewPeople?: boolean;
  };
  slackTeamId?: string;
  slackTeamName?: string;
  isDemo: boolean;
  isEmailVerified: boolean = false;
  restrictedFeatures: string[] = [];
  useLegacyAuthDomain: boolean = true;

  constructor(
    private afa: Auth,
    private tenantsService: TenantsService,
    private usersService: UsersService,
  ) {
    const {
      subdomain = null,
      tenantId = null,
      logo = null,
      primaryColor = null,
      authProviders = [],
      analyticsDisabled = false,
      phoneNumberRequirement = null,
      customProfileFields = [],
      useLegacyAuthDomain = true,
    } = getAppConfig() || {};

    this.subdomain = subdomain || 'app';
    this.tenantId = tenantId;
    this.logo = logo;
    this.primaryColor = primaryColor;
    this.authProviders = authProviders;
    this.isDemo = subdomain === 'piedpiper';
    this.analyticsDisabled = analyticsDisabled;
    this.phoneNumberRequirement = phoneNumberRequirement;
    this.customProfileFields = customProfileFields;
    this.useLegacyAuthDomain = useLegacyAuthDomain;

    this.load();
  }

  async load() {
    try {
      // NOTE: This must run before getting the user from auth state or the error may not be thrown.
      const credential = await getRedirectResult(this.afa);
      const user = await this.afa.currentUser;

      this.user = user;

      const idTokenResult = await user?.getIdTokenResult();

      this.idTokenResult = idTokenResult;
      this.isEmailVerified =
        idTokenResult?.signInProvider === 'password' ? (idTokenResult?.claims.email_verified as boolean) : true;

      // On first page load after verifying email, the token might have the wrong value, so refresh it.
      if (this.user?.emailVerified && !this.isEmailVerified) {
        this.idTokenResult = await this.user.getIdTokenResult(true);
        this.isEmailVerified = true;
      }

      this.credential = credential;
      this.isSuperAdmin = idTokenResult?.claims?.isSuperAdmin as boolean;

      if (this.isEmailVerified) {
        await Promise.all([
          user ? this.loadCompany() : Promise.resolve(null),
          user ? this.loadProfile() : Promise.resolve(null),
        ]);

        if (user) {
          this.usersService.setLastSeen().catch(console.error);
        }
      }

      document.cookie = `${COOKIE_PREFIX}=${this.userId}; domain=${document.location.hostname}; max-age=31536000; secure; path=/`;

      this.identifyUserForAnalytics();
      this.isReady = true;
      this.authReadyCallbacks.forEach((authReadyCallback) => authReadyCallback());
    } catch (error) {
      // If there was an error fetching the profile, log them out and set a flag
      // so the login page knows to show an error message.
      console.error('Error fetching profile', error);
      // eslint-disable-next-line import/namespace
      Sentry.captureException(error, {
        user: {
          id: this.user?.uid,
          tenantId: this.tenantId,
        },
      });

      this.setAuthError(error);
      this.logout();
    }
  }

  onAuthReady(authReadyCallback) {
    if (this.isReady) {
      authReadyCallback();
    } else {
      this.authReadyCallbacks.push(authReadyCallback);
    }
  }

  isLoggedIn() {
    return !!this.user;
  }

  get userPhoto() {
    return (this.profile && this.profile.photo) || (this.user && this.user.photoURL) || '';
  }

  get userId(): string {
    return (this.idTokenResult?.claims?.databaseId as string) ?? (this.idTokenResult?.claims?.user_id as string);
  }

  async getProfileById(id: string, isExternal: boolean = false) {
    return this.usersService.getUser(id, isExternal) as any;
  }

  async loadProfile() {
    const profile = await this.usersService.getMe();

    this.profile = profile as any;

    return profile;
  }

  async loadCompany() {
    try {
      const tenant = await this.tenantsService.getTenant();

      this.tenant = tenant;
      this.isCompanyAdmin = Boolean(this.user) && Boolean(tenant?.adminEmails?.includes(this.user.email));
      this.customProfileFields = tenant.users?.customProfileFields ?? [];
      this.allowedEmailDomains = tenant.allowedEmailDomains;
      this.homePageFilters = tenant.homePageFilters;
      this.integrations = {
        slackEnabled: tenant.integrations.slack !== 'disabled',
        teamsEnabled: tenant.integrations.teams !== 'disabled',
        googleCalendarEnabled: tenant.integrations.googleCalendar !== 'disabled',
        outlookEnabled: tenant.integrations.outlook !== 'disabled',
        workosEnabled: tenant.integrations.workos !== 'disabled',
        zoomEnabled: tenant.integrations.zoom !== 'disabled',
        marketplaceEnabled: tenant.integrations.marketplace !== 'disabled',
      };
      this.workos = tenant.workos;
      this.defaultFieldSettings = tenant.defaultFieldSettings;
      this.permissions = tenant.permissions;
      this.defaultCommunityId = tenant.defaultCommunityId;
      this.slackTeamId = tenant.slackTeamId;
      this.slackTeamName = tenant.slackTeamName;

      if (tenant.accountStatus) {
        this.accountStatus = tenant.accountStatus;
      }

      const stripeProductId = tenant.billing?.stripeProductId;

      // Some companies may have specially restricted features due to custom contract
      let restrictedFeatures = tenant.billing?.restrictedFeatures ?? [];

      if (tenant?.accountStatus === 'trial' && tenant.trialEndDate) {
        // Trial customers get access to everything but outbound email
        const end = moment(tenant.trialEndDate);
        const trialExpired = end.diff(moment()) < 0;

        if (trialExpired) {
          restrictedFeatures = _.values(FEATURES);
        } else {
          restrictedFeatures = [FEATURES.OUTBOUND_EMAIL];
        }
      } else {
        _.values(STRIPE_PRODUCTS).forEach((product) => {
          if (stripeProductId === product.productId) {
            // Our existing product catalog defines restricted features
            restrictedFeatures.push(...(product.restrictedFeatures ?? []));
          }
        });
      }

      this.restrictedFeatures = restrictedFeatures;

      return tenant;
    } catch {
      this.isCompanyAdmin = false;

      // Not an admin of this group. We'll get a permission error, but we can safely ignore this error.
    }
  }

  async updateTenant(data: Partial<Tenant>): Promise<void> {
    await this.tenantsService.updateTenant(data);
    await this.loadCompany();
  }

  setAuthError(authError: any) {
    window.sessionStorage.setItem(
      HAD_AUTH_ERROR_KEY,
      JSON.stringify({ message: authError.message, code: authError.code }),
    );
  }

  getAuthError() {
    const authErrorMessage = window.sessionStorage.getItem(HAD_AUTH_ERROR_KEY);

    window.sessionStorage.removeItem(HAD_AUTH_ERROR_KEY);

    return JSON.parse(authErrorMessage);
  }

  setWasManualLogoutFlag() {
    window.sessionStorage.setItem(WAS_MANUAL_LOGOUT, String(true));
  }

  getWasManualLogoutFlag() {
    const wasManualLogout = window.sessionStorage.getItem(WAS_MANUAL_LOGOUT) === String(true);

    window.sessionStorage.removeItem(WAS_MANUAL_LOGOUT);

    return wasManualLogout;
  }

  setAutoSigninFlag() {
    window.sessionStorage.setItem(WAS_AUTO_SIGN_IN, String(true));
  }

  getAutoSignInFlag() {
    const wasAutoSignIn = window.sessionStorage.getItem(WAS_AUTO_SIGN_IN) === String(true);

    window.sessionStorage.removeItem(WAS_AUTO_SIGN_IN);

    return wasAutoSignIn;
  }

  async logout() {
    (window as any).$unthread.inAppChat('stop');
    (window as any).$unthread.helpCenter('stop');

    if ((window as any)._cio?.reset) {
      (window as any)._cio?.reset();
    }

    await this.afa.signOut();

    document.cookie = `${COOKIE_PREFIX}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;

    // Prevent auto-login on a manual sign out.
    this.setWasManualLogoutFlag();

    window.location.reload();
  }

  identifyUserForAnalytics() {
    const name = this.profile?.name ?? this.user?.displayName;
    const userId = this.profile?.id ?? this.user?.uid;
    const email = this.profile?.email ?? this.user?.email;

    (window as any).$unthread.inAppChat('start', {
      user: this.profile
        ? {
            name,
            email,
            verificationHash: this.profile.widgetVerificationHash,
          }
        : this.user
          ? {
              name,
              email,
            }
          : undefined,
    });

    if (window.innerWidth < 540) {
      setTimeout(() => {
        (window as any).$unthread.inAppChat('toggleBubble', 'hide');
      }, 300);
    }

    if (name) {
      const nameComponents = name.split(' ');

      if ((window as any)._cio?.identify) {
        const updates: any = {
          // Required attributes
          id: userId,
          email,
          created_at: this.profile?.createdAt ? moment(this.profile.createdAt).unix() : null,

          // Optional
          first_name: nameComponents[0],
          last_name: nameComponents[nameComponents.length - 1],
          tenant_id: this.tenantId,
          tenant_subdomain: this.subdomain,
          tenant_account_status: this.accountStatus,
          is_tenant_admin: this.isCompanyAdmin,
          last_seen: moment().unix(),
        };

        if (this.tenant) {
          updates.tenant_trial_end_date = moment(this.tenant.trialEndDate).unix();
          updates.tenant_is_internal_support = this.tenant.isInternalSupport;
        }

        (window as any)._cio?.identify(updates);
      }

      if ((window as any).analytics?.identify) {
        (window as any).analytics?.identify(userId, {
          email,
          // Optional
          name,
          avatar: this.userPhoto,
          tenantId: this.tenantId,
          tenantIsInternalSupport: this.tenant?.isInternalSupport,
        });

        if (this.tenant) {
          (window as any).analytics?.group(this.tenantId, {
            name: this.tenant.name,
            tenantId: this.tenant.id,
            trialExpirationDate: moment(this.tenant.trialEndDate).unix(),
            isInternalSupport: this.tenant.isInternalSupport,
            subdomain: this.tenant.subdomain,
            stripeSubscriptionId: this.tenant.billing?.stripeSubscriptionId,
            accountStatus: this.tenant.accountStatus,
            llmConfig: this.tenant.llmConfig,
          });
        }
      }
    }
  }

  startHelpCenter() {
    const name = this.profile?.name ?? this.user?.displayName;

    (window as any).$unthread.helpCenter('init', {
      user: this.profile
        ? {
            name,
            email: this.profile.email,
            verificationHash: this.profile.helpCenterVerificationHash,
          }
        : this.user
          ? {
              name,
              email: this.user.email,
            }
          : undefined,
    });
  }

  stopHelpCenter() {
    (window as any).$unthread.helpCenter('stop');
  }

  isWorkosEnabled() {
    return this.workos?.directoryId && this.integrations?.workosEnabled;
  }

  sendCustomToken(customToken: string, origin: string) {
    function listener(event) {
      if (event.origin !== origin || event.data?.type !== CUSTOM_TOKEN_REQUEST_TYPE) {
        return;
      }

      // @ts-ignore
      event.source.postMessage({ type: CUSTOM_TOKEN_RESPONSE_TYPE, data: customToken }, event.origin);

      window.removeEventListener('message', listener, false);
    }

    window.addEventListener('message', listener, false);
  }

  getCustomToken(opener: Window) {
    return new Promise<string>((resolve) => {
      opener.postMessage({ type: CUSTOM_TOKEN_REQUEST_TYPE }, environment.internalBaseUrl);

      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== environment.internalBaseUrl || event.data?.type !== CUSTOM_TOKEN_RESPONSE_TYPE) {
            return;
          }

          resolve(event.data.data);
        },
        {
          once: true,
          capture: false,
        },
      );
    });
  }

  async refreshUser(user: User) {
    this.idTokenResult = await user.getIdTokenResult();
    this.isSuperAdmin = this.idTokenResult.claims?.isSuperAdmin as boolean;

    await this.loadProfile();
  }

  get isPrd() {
    return environment.environment === 'prd';
  }

  sendVerificationEmailForUser(user: User) {
    return sendEmailVerification(user, {
      url: document.location.origin,
    });
  }

  isAiFeatureEnabled(featureKey: string) {
    return this.tenant.aiFeatures && this.tenant.aiFeatures[featureKey] === true;
  }
}
